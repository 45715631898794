<template>
   <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>AIOT</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
           <el-breadcrumb-item>酒店列表</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
      <el-row class="content-box">
           <!-- 表头 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>租户/商户：</label>
                  <el-cascader
                           class="width-220"
                           v-model="companyIds"
                           :props="companies"
                           :show-all-levels="false">
                  </el-cascader>
               </el-row>
               <el-row class="search-item">
                  <label v-text="$t('msg.hotel_name_a')"></label>
                  <el-input class="width-220" v-model="condition" :placeholder="$t('msg.hotel_name_code')"  clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>时间范围：</label>
                  <date-packer class="width-220" :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
               </el-row>
               <el-button class="bg-gradient" type="primary" @click="handleQuery()" ><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary" @click="handleReset()"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 表格 -->
         <div class="table-box">
            <el-row class="table-head-btn">
               <el-row class="right-btn">
                  <el-button type="primary" @click="handleSynchro"  plain>人脸识别设备</el-button>
                  <el-button  class="bg-gradient" type="primary" @click="handleAddEdit('add')" ><span v-text="$t('msg.new_hotel')">新建酒店</span></el-button>
               </el-row>
            </el-row>
            <el-table
                  ref="singleTable"
                  v-loading="loading"
                  :data="tableData"
                  style="width: 100%"
                  highlight-current-row
                  @current-change="handleCurrentChange"
                  :stripe="true">
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column :label="$t('msg.logo')" width="70">
                  <template slot-scope="scope">
                     <img v-if="scope.row.hotelLogo" :src="scope.row.hotelLogo" width="40" height="40" class="suffix-cursor v-align-middle" @click="handleDetail(scope.row)"/>
                     <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="40" height="40" class="suffix-cursor v-align-middle" @click="handleDetail(scope.row)" alt="">
                  </template>
               </el-table-column>
               <el-table-column prop="hotelName" :label="$t('msg.hotel_name')" width="120"></el-table-column>
               <el-table-column prop="hotelCode" :label="$t('msg.hotel_code')" width="120"></el-table-column>
               <el-table-column prop="telephone" :label="$t('msg.hotel_phone')" width="120"></el-table-column>
               <el-table-column prop="address" :label="$t('msg.contact_address')" width="300"></el-table-column>
               <el-table-column prop="companyName" :label="$t('msg.affiliation')" width="200"></el-table-column>
               <el-table-column prop="createTime" :label="$t('msg.create_time')" width="200" sortable></el-table-column>
               <el-table-column :label="$t('msg.operating')" :min-width="operWidth" fixed="right">
                  <template slot-scope="scope">
                     <!-- <el-button  type="text" icon="el-icon-s-operation" @click="getSecretKey(scope.row.id)" >
                        <el-link type="primary">秘钥配置</el-link>
                     </el-button> -->
                     <el-button  type="text" icon="el-icon-s-operation" @click="visibleSecret = true" v-if="scope.row.bindState===undefined">
                        <el-link type="primary">绑定酒店</el-link>
                     </el-button>
                     <el-button  type="text" icon="el-icon-s-operation" @click="handleRoomTypes(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.roomtype')">房型</el-link>
                     </el-button>
                     <el-button  type="text" icon="el-icon-notebook-2" @click="handlePms(scope.row)" >
                     <el-link type="primary" v-text="$t('msg.pms_config')">PMS配置</el-link>
                     </el-button>
                     <el-button  type="text" icon="el-icon-edit" @click="handleAddEdit('edit', scope.row)" >
                     <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button  type="text" style="color: #F56C6C;" icon="el-icon-circle-close" @click="handleUnbind(scope.row)" v-if="scope.row.bindState==='YES'">
                     <el-link type="danger" >解绑</el-link>
                     </el-button>
                     <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row)" >
                     <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
         </div>
         <!-- 秘钥配置 -->
         <!-- <el-dialog title="秘钥配置" :visible.sync="visibleSecret"  width="550px">
            <el-form class="form-dialog-box" :rules="secretRules" :model="secretForm" ref="secretForm">
               <el-form-item label="key：">
                  {{ secretForm.hotelKey }}
               </el-form-item>
               <el-form-item label="secret：" prop="hotelSecret">
                  <el-input v-model="secretForm.hotelSecret" placeholder="secret"></el-input>
                  <el-button class="m-left-10" type="primary" @click="randomSecret(32)">自动生成</el-button>
               </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
               <el-button @click="visibleSecret = false">取 消</el-button>
               <el-button type="primary" @click="saveSecret">确 定</el-button>
            </div>
         </el-dialog> -->
         <el-dialog title="绑定酒店" :visible.sync="visibleSecret"  width="550px">
            <el-form class="form-dialog-box" :rules="secretRules" :model="secretForm" ref="secretForm">
               <el-form-item label="酒店秘钥" prop="hotelSecret">
                  <el-input v-model="secretForm.hotelSecret" style="width: 315px"></el-input>
               </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
               <el-button @click="visibleSecret = false">取 消</el-button>
               <el-button type="primary" @click="saveSecret">确 定</el-button>
            </div>
         </el-dialog>
         <!-- 房型照片编辑 -->
         <el-form :class="dialogVisible?'uploadImg':''" label-width="105px">
            <el-dialog :title="$t('msg.room_type_photo_edit')" :visible.sync="isShowRoomT" width="760px">
               <div class="config-box config-display">
                  <div class="device-box">
                     <p class="p-title build-title">
                        <span>房型列表</span>
                        <span class="p-title-add" @click="isShowAddRoomT = true"><i class="el-icon-circle-plus"></i><span class="p-left-5" v-text="$t('msg.add')">添加</span></span>
                     </p>
                     <el-row class="m-top-15 m-left-15" v-for="(item, index) in roomTypes" :key="index">
                        <el-col :span="3">
                           <span class="p-left-10"><i class="el-icon-picture"></i></span>
                        </el-col>
                        <el-col :span="21" @click.native="selectRoomType(item)">
                           <span :class="[item.id === roomTypeId ? 'active' : '']" class="p-left-10" v-text="item.roomTypeName"></span>
                        </el-col>
                     </el-row>
                  </div>
                  <div class="device-box room-box">
                     <p class="p-title build-title">
                        <span>房型照片</span>
                     </p>
                     <el-row class="room-box-img">
                        <div v-for="(item, index) in roomTypeImgs" :key="index" @mouseover="handleShow(item.id)" @mouseleave="handleHide">
                           <img width="148" height="148" :src="item.imgUrl" alt="">
                           <span :style="{'display': roomTypeImgId === item.id ? 'block' : 'none'}"><i @click="handleDelImg(item.id)" class="el-icon-delete"></i></span>
                        </div>
                     </el-row>
                     <el-row class="m-left-15">
                        <el-upload
                                 ref="upload"
                                 multiple
                                 :limit="3"
                                 :show-file-list="true"
                                 action="#"
                                 :auto-upload="false"
                                 list-type="picture-card"
                                 :on-change="handleChange">
                           <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                           <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                     </el-row>
                  </div>
               </div>
               <el-row style="margin: 15px 0 25px 0">
                  <el-col :span="6" style="float: right">
                     <el-button  @click="isShowRoomT = false" v-text="$t('msg.cancel')">取消</el-button>
                     <el-button class="bg-gradient" type="primary" @click="saveRoomTypePhoto()">确定</el-button>
                  </el-col>
               </el-row>
            </el-dialog>
         </el-form>
         <!-- 新增酒店房型 -->
         <el-form label-width="105px">
            <el-dialog :title="$t('msg.add_room_type')" :visible.sync="isShowAddRoomT" width="510px" :before-close="closeAddRoomType">
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.room_icon_a')">图标：</span></el-col>
                  <el-col :span="16" style="margin: 5px 0;">
                     <template>
                        <el-radio v-model="radioIcon" label="1"><span v-text="$t('msg.font_icon')">字体图标</span></el-radio>
                        <el-radio v-model="radioIcon" label="2"><span v-text="$t('msg.upload_icon')">上传图标</span></el-radio>
                     </template>
                  </el-col>
                  <el-col :span="16" class="suffix-icon">
                     <el-input  class="width-260" v-if="radioIcon === '1'" :placeholder="$t('msg.font_icon')" clearable></el-input>
                     <el-input  class="width-260" v-else :placeholder="$t('msg.upload_icon')">
                        <i slot="suffix" class="el-input__icon el-icon-upload"></i>
                     </el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.roomtype_name_a')">房型名称：</span></el-col>
                  <el-col :span="16">
                     <el-input  class="width-260" v-model="roomTypeName" :placeholder="$t('msg.roomtype_name')" clearable></el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.roomtype_code_a')">房型编码：</span></el-col>
                  <el-col :span="16">
                     <el-input  class="width-260" v-model="roomTypeCode" :placeholder="$t('msg.roomtype_code')" clearable></el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><span v-text="$t('msg.remarks_a')">备注信息：</span></el-col>
                  <el-col :span="16">
                     <el-input class="width-260 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
                  </el-col>
               </el-row>
               <span slot="footer" class="dialog-footer">
               <el-button  @click="add_reset_room()" v-text="$t('msg.reset')">重置</el-button>
               <el-button  type="primary" @click="saveAddRoomType()" v-text="$t('msg.save')">保存</el-button>
            </span>
            </el-dialog>
         </el-form>
         <!-- pms配置 -->
         <el-form label-width="105px" class="pms_config_list">
            <el-dialog :title="$t('msg.pms_config')" :visible.sync="isShowPms" width="700px" :before-close="closePms">
               <el-row class="m-bottom-15">
                  <el-col :span="12">
                     <label>PMS选择：</label>
                     <el-select  class="width-193" v-model="pmsId" :placeholder="$t('msg.pms_city')" clearable>
                        <el-option v-for="item in compassOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                     </el-select>
                  </el-col>
                  <el-col :span="12">
                     <label>PMS酒店编码：</label>
                     <el-input  class="width-193" v-model="hotelPmsCode" :placeholder="$t('msg.pms_hotel_code')" maxLength='50' clearable></el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <label>房型数据从eBooking获取：</label>
                  <el-checkbox v-model="isEbooking"></el-checkbox>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="21"><span v-text="$t('msg.pms_config_all')">PMS参数配置项</span></el-col>
                  <el-col :span="3" class="t-right">
                     <el-button class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-plus" @click="handleAddPms" ><span v-text="$t('msg.add')">添加</span></el-button>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-table
                           ref="singleTable"
                           :data="configAllList"
                           border
                           highlight-current-row
                           style="width: 100%"
                           :stripe="true"
                           >
                     <el-table-column type="index" :label="$t('msg.number')" width="50"></el-table-column>
                     <el-table-column prop="name" :label="$t('msg.config_name')" min-width="200">
                        <template slot-scope="scope">
                           <span v-if="scope.row.active" v-text="scope.row.name"></span>
                           <el-input v-else  class="width-193" v-model="scope.row.name" :placeholder="$t('msg.config_name')" maxLength='50' clearable></el-input>
                        </template>
                     </el-table-column>
                     <el-table-column prop="value" :label="$t('msg.config_value')" min-width="200">
                        <template slot-scope="scope">
                           <span v-if="scope.row.active" v-text="scope.row.value"></span>
                           <el-input v-else  class="width-193" v-model="scope.row.value" :placeholder="$t('msg.config_value')" maxLength='50' clearable></el-input>
                        </template>
                     </el-table-column>
                     <el-table-column :label="$t('msg.operating')" min-width="140"  fixed="right">
                        <template slot-scope="scope">
                           <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDelPms(scope)" >
                              <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                           </el-button>
                           <!-- <el-button  type="text" icon="el-icon-edit" @click="build_edit(scope.row)" >
                              <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                           </el-button> -->
                        </template>
                     </el-table-column>
                  </el-table>
               </el-row>
               <span slot="footer" class="dialog-footer">
               <el-button  @click="cancelPmsCfg()" v-text="$t('msg.cancel')">取消</el-button>
               <el-button class="bg-gradient" type="primary" @click="savePmsCfg()" v-text="$t('msg.save')">保存</el-button>
            </span>
            </el-dialog>
         </el-form>
      </el-row>
   </div>
</template>
<script>
import { urlObj  } from '@/api/interface'
import { system } from '@/api/interface/system'
import { randomNum } from '@/common/js/common'
export default {
   name: 'Hotel',
   data () {
      let validSecret = (rule, value, callback) => {
         if (value === '') callback(new Error('请输入秘钥'))
         else if (value.length > 32) callback(new Error('秘钥长度不能大于32位'))
         else callback()
      }
      let self = this
      return {
         companies: {      // 公司列表
            lazy: true,
            checkStrictly: true,
            async lazyLoad(node, resolve){
               const { value,hasChildren } = node
               // 如果没有子节点则无需发起请求
               if (hasChildren === false) return resolve([])
               let data = await self.getCompanies(value)
               resolve(data)
            }
         },
         tableData: [],         // 表格数据
         roomTypes: [],         // 酒店房型类型
         loading: true,         // 表格加载效果
         isShowRoomT: false,    // 是否显示房型编辑弹窗
         isShowPms: false,      // 是否显示PMS弹窗
         isShowAddRoomT: false, // 是否显示新建房型弹窗
         hotelId: '',           // 当前酒店id
         total: 0,              // 表格数据总数
         radioIcon: '1',        // 图标按钮
         operWidth: 0,          // 编辑栏宽度
         /* 秘钥 */
         visibleSecret: false,
         secretForm: {
            hotelKey: '',
            hotelSecret: ''
         },
         secretRules: {
            hotelSecret: [{ required: true, message:'请输入酒店秘钥', trigger: 'blur' }]
         },
         /* 查询酒店列表字段 */
         limit: 0,              // 每页数
         page: 1,               // 当前页
         storeId: '',           // 公司ID
         condition: '',         // 酒店名称/编码
         beginTime: '',         // 开始时间
         endTime: '',           // 结束时间
         companyIds: [],        // 租户/商户ID
         /* PMS配置 */
         compassOpt: [],        // PMS列表
         configAllList: [],     // PMS配置项列表
         pmsId: '',             // PmsID
         hotelPmsCode: '',      // PMS酒店编码
         isEbooking: false,     // 是否开启ebooking
         /* 添加房型 */
         roomTypeName: '',      // 房型名称
         roomTypeCode: '',      // 房型编码
         remark: '',            // 备注
         /* 房型照片编辑 */
         files: [],             // 文件
         roomTypeImgs: [],      // 房型图片列表
         roomTypeId: '',        // 房型ID
         roomTypeImgId: '',     // 房型照片ID
         dialogVisible: false,  // 是否显示放大图
         dialogImageUrl: '',    // 放大图地址
         fileList: [],
         dateValue: [], // 添加时间
         addHotel: false, // 新建酒店
         dUploadIcon: '', // 上传图标
         dContent: '', // PMS酒店编码(PMS配置)
         /* 提示文本 */
         save_room_success: '保存房型成功！',
         confirm: '确定',
         cancel: '取消',
         hotel_no_null: '酒店名称不能为空！',
         hotel_code_no_null: '酒店编码不能为空！',
         hotel_type_no_null: '酒店类型不能为空！',
         start_floor_no_null: '开始楼层不能为空！',
         end_floor_no_null: '结束楼层不能为空！',
         start_floor_no_end: '开始楼层不能大于结束楼层！',
         sel_up_hotel: '请选择上级酒店！',
         hotel_phone_no_null: '酒店电话不能为空！',
         mailbox_error: '邮箱格式不正确！',
         select_applet: '请选择小程序授权菜单！',
         add_success: '添加成功！',
         update_success: '修改成功！',
         prompt: '提示！',
         pms_edit: 'pms配置已更改，是否保存配置？',
         confirm_edit: '确定修改？',
         confirm_remove: '确定移除？',
         confirm_remove_hotel: '若删除酒店，将删除其下面所有的部门、人员数据，确定删除？',
         remove_success: '移除成功！',
         bind_success: '绑定成功！',
         room_bind_no_null: '请选择要绑定的房型！',
         room_type_no_null: '房间类型(中文)不能为空！',
         room_type_en_no_null: '房间类型(英文)不能为空！',
         room_type_no_equals: '房间类型不能相同！',
         room_type_no_def_equals: '房间类型不能跟默认的相同！',
         build_name_no_null: '楼栋名称不能为空！',
         build_nubmer_no_null: '楼栋编号不能为空！',
         unit_name_no_null: '单元名称不能为空！',
         unit_nubmer_no_null: '单元编号不能为空！',
         room_type_name_no_null: '房型名称不能为空！',
         room_type_code_no_null: '房型编码不能为空！',
         upload_photo_success: '上传房型照片成功！',
         config_no_null: '配置项不能为空！'
      }
   },
   beforeRouteEnter(to, from, next){
      if (from.name === 'HotelAddDetail' || from.name === 'HotelAddBasic'){
         next(vm => vm.getHotels())
         return
      }
      next()
   },
   beforeRouteLeave(to, from, next){
      if (to.name === 'HotelAddBasic'){
         this.$store.dispatch('setCachePage', from.name)
         next()
         return
      }
      this.$store.dispatch('setCachePage', 'fakePage')
      next()
   },
   mounted () {
      this.limit = sessionStorage.getItem('pageSize') * 1 // 每页显示数
      this.storeId = this.$route.query.storeId
      this.getHotels()
   },
   methods: {
      // 获取租户/商户列表
      getCompanies(companyIds){
         return new Promise((resolve, reject) => {
            let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
            const url = system.companies
            let param = {}
            if (userInfo.accountType === 'PLATFORM' ||
                  userInfo.accountType === 'TENANT') param.type = 'TENANT'
            if (userInfo.accountType === 'STORE') param.type = 'STORE'
            if (companyIds){ param.type = 'STORE'; param.tenantId = companyIds }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.records) {
                  let companies = res.records
                  companies = companies.map(item => {
                     let leaf
                     if (item.leaf) leaf = true
                     return {value: item.id, label: item.companyName, leaf}
                  })
                  resolve(companies)
               }
            })
         })
      },
      // 获取酒店列表
      getHotels(){
         const url = urlObj.getHotelPage + `?limit=${this.limit}&page=${this.page}`
         const param = {
            condition: this.condition,
            beginTime: this.beginTime,
            endTime: this.endTime,
            tenantId: this.companyIds[0]
         }
         if (this.companyIds.length > 1) param.storeId = this.companyIds[1]
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.loading = false
               this.total = res.total
               let tableData = res.records
               tableData.forEach(item => {
                  item.hotelLogo = item.hotelLogo && window.getResourcesHost() + item.hotelLogo
                  item.address = (item.province && item.province.split(',')[1]) +
                        (item.city && item.city.split(',')[1]) +
                        (item.district && item.district.split(',')[1]) + item.street
               })
               this.tableData = tableData
               this.$refs.singleTable.setCurrentRow(this.tableData[0])
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
      // 获取日期段值
      getDatePacker(val){
         this.dateValue = val
      },
      // 搜索酒店
      handleQuery(){
         this.page = 1
         if (this.dateValue) {
            this.beginTime = this.dateValue[0]
            this.endTime = this.dateValue[1]
         } else {
            this.beginTime = ''
            this.endTime = ''
         }
         this.getHotels()
      },
      // 重置搜索
      handleReset(){
         this.page = 1
         this.companyIds = []   //租户/商户
         this.condition = ''    // 酒店名称
         this.dateValue = []    // 操作时间
         this.storeId = ''
      },
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.getHotels()
      },
      // 改变当前页
      currChange(num){
         this.page = num
         this.getHotels()
      },
      // 选中表格行
      handleCurrentChange(val){
         this.hotelId = val.id
      },
      // 同步人脸设备
      handleSynchro(){
         this.$router.push({path: '/synchro', query: {'hotelId': this.hotelId}})
      },
      // 添加/编辑酒店
      handleAddEdit(action, row){
         if (action === 'edit') sessionStorage.setItem('hotelObj', JSON.stringify(row))
         this.$router.push({path: '/hotel_add_basic', query: {action}})
      },
      // 酒店详情
      handleDetail(row){
         this.$router.push('/hotel_detail?id=' + row.hotelId)
      },
      // 删除酒店
      handleDel(row) {
         this.$confirm(this.confirm_remove_hotel, this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'error'
         }).then(() => {
            const url = urlObj.deleteHotel + `/${row.id}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.remove_success,
                     type: 'success'
                  })
                  this.getHotels()
               }
            })
         })
      },
      // 解绑
      handleUnbind(row){
         this.$confirm('您确定解绑酒店绑定吗?', this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'error'
         }).then(() => {
            const url = urlObj.unBindPaasHotel + `/${row.id}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: '解绑成功',
                     type: 'success'
                  })
                  this.getHotels()
               }
            })
         })
      },
      // 绑定 酒店
      saveSecret() {
         this.$refs.secretForm.validate(valid => {
            if (!valid) return
            const url = urlObj.bindPaasHotel
            const param = { hotelId: this.hotelId, secretKey:this.secretForm.hotelSecret }
            this.$axios.post(url, param, 'json').then(res => {
               if (!res.success) return
               this.visibleSecret = false
               this.$message({ message: '配置成功！', type: 'success' })
               this.getHotels()
            })
         })
      },
      // PMS配置
      handlePms(row) {
         this.isShowPms = true
         this.hotelId = row.id
         this.getPmsList()
         this.getAllPmsList()
      },
      // PMS配置 获取PMS列表
      getPmsList(){
         const url = urlObj.getHotelPms + `/${this.hotelId}`
         this.$axios.get(url, {}).then(res => {
            if (res.success) {
               let records = res.records
               if (!res.records || records.length <= 0) {
                  this.dContent = ''
                  this.configAllList = []
                  return
               }
               let tempArr = []
               let tempObj = {}
               this.pmsId = records.pmsId
               this.hotelPmsCode = records.hotelPmsCode
               this.isEbooking = records.isEbooking === 'YES' ? true : false
               let tempContent = JSON.parse(records.content)
               this.dContent = records.content
               for (let i in tempContent) {
                  tempObj.active = true
                  tempObj.name = i
                  tempObj.value = tempContent[i]
                  tempArr.push(tempObj)
                  tempObj = {}
               }
               this.configAllList = tempArr
            }
         })
      },
      // PMS配置 获取全部PMS列表
      getAllPmsList(){
         const url = urlObj.getPmsList
         this.$axios.post(url, {}).then(res => {
            if (res.success) {
               let compassOpt = res.records
               this.compassOpt = compassOpt.map(item => ({value: item.id, label: item.pmsName}))
            }
         })
      },
      // PMS配置 添加配置
      handleAddPms(){
         let obj = { name: '', value: '', active: false }
         this.configAllList.push(obj);
         obj = {}
      },
      // PMS配置 删除配置
      handleDelPms(scope){
         this.configAllList.splice(scope.$index, 1);
      },
      // PMS配置 配置项数组转字符串
      configList (List) {
         if (!List ||List.length <= 0) return ''
         let content = {}
         List.forEach((item, index) => content[item.name] = item.value)
         return JSON.stringify(content)
      },
      // PMS配置 取消PMS操作
      cancelPmsCfg () {
         this.pmsId = ''
         this.hotelPmsCode = ''
         this.isEbooking = false
         /* 修改操作时未保存 取消提示 */
         if (this.dContent !== this.configList(this.configAllList)) {
            this.$confirm(this.pms_edit, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(async () => {
               this.savePmsCfg()
            }).catch(cancel => {
               // console.log(cancel)
            })
         } else {
            this.isShowPms = false
         }
      },
      // PMS配置 保存pms配置
      savePmsCfg(){
         // 验证
         if (this.configAllList.length === 0) {
            this.$alert(this.config_no_null, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         const url = urlObj.addHotelPms
         const param = {
            hotelId: this.hotelId,
            pmsId: this.pmsId,
            hotelPmsCode: this.hotelPmsCode,
            isEbooking: this.isEbooking ? 'YES' : 'NO',
            content: this.configList(this.configAllList)
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.$message({
                  showClose: true,
                  message: this.update_success,
                  type: 'success'
               })
              let hotelInfo = JSON.parse(sessionStorage.getItem('hotelInfo'))
              if(hotelInfo.isEbooking == 'YES'){
                hotelInfo.isEbooking = 'NO'
                this.$store.dispatch('setHotelInfo', hotelInfo)
                sessionStorage.setItem('hotelInfo',JSON.stringify(hotelInfo))
              }else{
                hotelInfo.isEbooking = 'YES'
                this.$store.dispatch('setHotelInfo', hotelInfo)
                sessionStorage.setItem('hotelInfo',JSON.stringify(hotelInfo))
              }
               this.isShowPms = false
            }
         })
      },
      // PMS配置 关闭弹窗
      closePms(){
         this.isShowPms = false
      },
      // 房型照片
      handleRoomTypes(row){
         this.hotelId = row.id
         this.isShowRoomT = true
         this.getRoomTypes()
      },
      // 房型照片 获取酒店下房型列表
      getRoomTypes(){
         const url = urlObj.getHotelRoomTypeList
         const param = {
            hotelId: this.hotelId
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.roomTypes = res.records
               if (this.roomTypes.length === 0) return
               this.roomTypeId = this.roomTypes[0].id
               this.getRoomTypePhotos()
            }
         })
      },
      // 房型照片 保存新增房型
      saveAddRoomType(){
         // 房型名称(新建房型)
         if (!this.roomTypeName) {
            this.$alert(this.room_type_name_no_null, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         // 房型编码(新建房型)
         if (!this.roomTypeCode) {
            this.$alert(this.room_type_code_no_null, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         const url = urlObj.addBindRoomType
         let param = {
            hotelId: this.hotelId,
            roomTypeName: this.roomTypeName,
            roomTypeCode: this.roomTypeCode,
            remark: this.remark
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.$message({
                  showClose: true,
                  message: this.add_success,
                  type: 'success'
               })
               this.closeAddRoomType()
               this.getRoomTypes()
            }
         })
      },
      // 房型照片 关闭新增房型弹窗
      closeAddRoomType(){
         this.isShowAddRoomT = false
         this.roomTypeName = ''
         this.roomTypeCode = ''
         this.remark = ''
      },
      // 房型照片 选中当前房型
      selectRoomType(item){
         this.roomTypeId = item.id
         this.getRoomTypePhotos()
      },
      // 房型照片 获取房型照片列表
      getRoomTypePhotos(){
         const url = urlObj.roomTypeImgs + `/${this.roomTypeId}`
         this.$axios.get(url, {}).then(res => {
            if (res.success){
               let data = res.records
               this.roomTypeImgs = data.map(item => {
                  let imgUrl = window.getResourcesHost() + item.location + '/' + item.formatName
                  return {id: item.id, imgUrl}
               })
            }
         })
      },
      // 房型照片 添加文件资源
      handleChange(file){
         this.files.push(file.raw)
      },
      // 房型照片 循环发起保存照片请求
      async saveRoomTypePhoto(){
         const url = urlObj.uploadRoomTypeImg
         let param = new FormData()
         param.append('id', this.roomTypeId)
         param.append('module', 'hotel')
         param.append('menu', 'list')
         param.append('func', 'roomtype')
         param.append('category', 'image')
         param.append('isThumb', true)
         param.append('bizId', this.roomTypeId)
         for (let i = 0;i <= this.files.length - 1;i ++){
            param.set('file', this.files[i])
            await this.uploadPhoto(url, param)
         }
      },
      // 房型照片 保存房型照片
      uploadPhoto(url, param){
         return new Promise((resolve, reject) => {
            this.$axios.post(url, param, 'file').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.upload_photo_success,
                     type: 'success'
                  })
                  this.$refs.upload.clearFiles()
                  this.getRoomTypePhotos()
               }
               resolve('success')
            }).catch(error => {
               resolve('success')
            })
         })
      },
      // 房型照片 移入显示删除按钮
      handleShow(id){
         this.roomTypeImgId = id
      },
      // 房型照片 移出隐藏删除按钮
      handleHide(){
         this.roomTypeImgId = ''
      },
      // 房型照片 删除房型照片
      handleDelImg(id){
         const url = urlObj.delImg + `/${id}`
         this.$axios.get(url, {}).then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.remove_success,
                  type: 'success'
               })
               this.getRoomTypePhotos()
            }
         }).catch(error => {
            this.$message.error(error)
         })
      }
   }
}
</script>
<style scoped lang="scss">
   .room-box-img{
      display: flex;
      flex-wrap: wrap;
      margin: 15px 0 12px 15px;
   }
   .room-box-img div{
      position: relative;
      margin: 4px;
   }
   .room-box-img span{
      width: 100%;
      height: 100%;
      background-color: #333333;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.6;
      color: #ffffff;
      line-height: 135px;
      text-align: center;
      font-size: 24px;
   }
   .active{
      color: #409eff;
   }
    .box-info{
        width: 80%;
        margin: 0 auto;
        /deep/ .el-input__inner{
            height: 40px;
            line-height: 40px;
        }
        .rate-number{
            /deep/ .el-rate__icon{
                height: 40px;
                line-height: 40px;
                font-size: 30px;
            }
        }
    }
    .box_list{
        .el-col-4{
            height: 50px;
            line-height: 50px;
            padding-left: 15px;
            border: 1px solid rgba(204, 204, 204, 1);
            border-width: 0 1px 1px 0;
            &:nth-child(6n){
                border-right: 0;
            }
        }
        .box-inline{
            display: inline-block;
            cursor: pointer;
        }
    }
    .box-bg{
        background: rgba(204, 204, 204, 0.3);
        border-bottom: 1px solid rgba(204, 204, 204, 1);
    }
    .box-border{
        border: 1px solid rgba(204, 204, 204, 1);
    }

  .t-right > .font-icon{
    font-size:30px;
    margin-right: 10px;
    color: #409EFF;
    cursor: pointer;
  }
  .hint-box{
    border:#00c1de solid 1px;
    width:445px;
    background:#e6f9fc;
    border-radius:3px;
    padding:12px;
  }
  .type-box{
    border: 1px solid #dedede;
    margin-top: 10px;
    padding-top: 10px;
    max-height: 330px;
    overflow:auto;
  }
  .cks{
    line-height:28px!important;
    margin-right: 10px!important;
  }
  .disc-p{
    color: #409EFF;
    margin-left: 56px;
  }
  .def-type-box{
    /* border: 1px solid red; */
    line-height: 26px;
    color: #67c23a;
  }
  .lab-box{
    color: #409EFF;
  }
  .def-type-box > span{
    display:inline-block;
    width:141px;
    padding-left:18px;
  }
  .def-type-box > .def-cn{
    margin-left:55px;
  }
  .def-type-box > .def-en{
    width:180px;
  }
  .edit_hotel >>> .el-dialog{
    min-height: 430px;
  }
  .uploadImg{
    position: relative;
    z-index: 2222;
  }
  .p-title{
    color: #FFF;
    background:#409EFF;
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    &.build-title{
      line-height: 42px;
    }
    .p-title-add{
      padding-left: 10px;
      cursor: pointer;
    }
  }
  .config-display{
    display: flex;
    justify-content: space-between;
  }
  .device-box{
    border:1px solid #dedede;
    box-sizing: border-box;
    display: inline-block;
    height: 400px;
    width: 27%;
    overflow: auto;
    &.room-box{
      width: 70%;
      /deep/ .el-icon-delete {
        vertical-align: middle !important;
      }
    }
    &.build-box{
      width: 49%;
    }
    .build-border-bottom{
      border-bottom: 1px solid #ccc;
      box-sizing: border-box;
      .build-padding{
        padding: 5px 0;
        height: 30px;
        i{
          cursor: pointer;
        }
      }
      .build-border-right{
        border-right: 1px solid #ccc;
        box-sizing: border-box;
      }
    }
  }
  .pms_config_list{
    .m-bottom-15{
      padding: 0 15px;
    }
  }
  .add-btn-box{
    box-sizing: border-box;
    display:inline-block;
    height: 400px;
    width: 88px;
    text-align: center;
    overflow: hidden;
  }
  .del-icon{
    color: #F56C6C;
    cursor: pointer;
  }
  .build-back{
    background: rgba(0,0,0,0.6);
    color: white;
  }
  .device-box .el-radio{
    margin-right: 10px!important;
  }
  .device-box .el-radio__label{
    padding-left: 5px!important;
  }
</style>
